'use strict';

const filterBarFilters = '.filter-bar > ul';

// Function to fix/unfix refinement bar to top of screen,
// also adds margin-top to the page when the refinement bar is fixed.
const updateRefinementBar = (status) => {
    const $refinementBar = $(filterBarFilters);
    const $page = $('#maincontent');
    const refinementBarHeight = $refinementBar.outerHeight();

    if (status === 'fixed') {
        $refinementBar.addClass('fixed');
        $page.css('margin-top', `${refinementBarHeight}px`);
    } else {
        $refinementBar.removeClass('fixed');
        $page.css('margin-top', '');
    }
};

const getHeight = (selector) => {
    const $element = $(selector);
    return $element.length ? $element.outerHeight() : 0;
};

module.exports = () => {
    // Fix/unfix header to top of screen, dependent on class added by custom preference
    // Note: This is intentionally not using utilities/scroll.js so that it can fix immediately vs. being debounced. Should be replaced with CSS position:sticky once IE11 support is dropped.
    $(window).scroll((event) => {
        if ($(filterBarFilters).length) {
            // Get heights of elements above the filters to determine when to fix/unfix the refinement bar.
            const filterBarHeight = window.isMobile() ? 0 : getHeight('.refinement-bar');
            const refinementBarHeight = getHeight(filterBarFilters);
            const gridHeaderHeight = getHeight('.grid-header');
            const headerBannerHeight = getHeight('.header-banner');
            const searchResultsHeaderHeight = getHeight('.search-results-header');
            const resultCountHeight = getHeight('.filter-bar .result-count');

            const scrollTop = $(event.target).scrollTop();
            const combinedHeight = refinementBarHeight + searchResultsHeaderHeight + headerBannerHeight + filterBarHeight + resultCountHeight + gridHeaderHeight;

            updateRefinementBar(scrollTop > combinedHeight ? 'fixed' : 'unfixed');
        }
    });
};
