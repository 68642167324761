var exports = $.extend({}, require('core/search/search'));
var SiteConstants = require('constants/SiteConstants');
var headerUtils = require('core/utilities/headerUtils');

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results, selector) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });
    updateDom($results, selector);
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var isMobile = window.isMobile();

    if (isMobile) {
        var $refinements = $results.find('.refinements[data-refinement-style-mobile]');
        var refinementStyle = $refinements.data('refinement-style-mobile');
        var selector = `[data-refinement-style-mobile="${refinementStyle}"]`;
    } else {
        var $refinements = $results.find('.refinements[data-refinement-style-desktop]');
        var refinementStyle = $refinements.data('refinement-style-desktop');
        var selector = `[data-refinement-style-desktop="${refinementStyle}"]`;
    };

    var $filterBar = $results.find('.filter-bar');
    var filterBarStyle = !isMobile ? $filterBar.attr('data-filter-bar-style-desktop') : $filterBar.attr('data-filter-bar-style-mobile');
    var filterBar = !isMobile ? '[data-filter-bar-style-desktop="' + filterBarStyle + '"]' : '[data-filter-bar-style-mobile="' + filterBarStyle + '"]';

    var verticalMobile = isMobile && selector === '[data-refinement-style-mobile="vertical"]';
    var verticalDesktop = !isMobile && selector === '[data-refinement-style-desktop="vertical"]';
    var horizontalMultipleDropdownsDesktop = !isMobile && selector === '[data-refinement-style-desktop="horizontal-multiple-dropdowns"]';
    var horizontalOneDropdownDesktop = !isMobile && selector === '[data-refinement-style-desktop="horizontal-one-dropdown"]';
    var horizontalOneDropdownDesktopActive = $('.collapse-filters-wrapper').hasClass('active');
    var horizontalOneDropdownDesktopOpen = false;

    if (horizontalOneDropdownDesktop && horizontalOneDropdownDesktopActive) {
        horizontalOneDropdownDesktopOpen = true;
    }

    if (verticalMobile || verticalDesktop) {
        var specialHandlers = {
            selector : handleRefinements
        };

        Object.keys(specialHandlers).forEach(function (selector) {
            specialHandlers[selector]($results, selector);
        });
    };

    if (!isMobile) {
        var refinements = '.desktop-search-refinements';
    } else {
        var refinements = '.mobile-search-refinements';
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        refinements,
        filterBar
    ].forEach((selector) => {
        updateDom($results, selector);
    });

    if (horizontalOneDropdownDesktopOpen === true) {
        $('.collapse-filters-wrapper').addClass('active').css('display', 'block');
    }

    $('body').trigger('ajax:load.ajaxEvents', [$('.search-results')]);

    exports.refinementStickyBar();
    exports.oneDropdownSlideToggle();
}

var toggleRefinementDrawer = status => {
    var $refinementBar = $('.refinement-bar');
    var $modalBackground = $('.modal-background');

    if (status === 'open') {
        var headerNavHeight = headerUtils.getHeaderHeight();
        var scrollTopHeight = $('header').offset().top;

        $('html').scrollTop(scrollTopHeight);
        // Following two lines for MS Edge to work
        document.body.scrollTop = scrollTopHeight;
        document.documentElement.scrollTop = scrollTopHeight;
        $('html').addClass('lock-scroll');
        $('body').addClass('mobile-filter-drawer-in');
        $refinementBar.addClass('in').css('top', headerNavHeight).siblings().attr('aria-hidden', true);
        $modalBackground.fadeIn(SiteConstants.TransitionSpeed).css('top', headerNavHeight);
        $refinementBar.closest('.row').siblings().attr('aria-hidden', true);
        $refinementBar.closest('.tab-pane.active').siblings().attr('aria-hidden', true);
        $refinementBar.closest('.container.search-results').siblings().attr('aria-hidden', true);
        $refinementBar.find('.close').focus();
    } else {
        $('html').removeClass('lock-scroll');
        $('body').removeClass('mobile-filter-drawer-in');
        $refinementBar.removeClass('in').siblings().attr('aria-hidden', false);
        $modalBackground.fadeOut(SiteConstants.TransitionSpeed);
        $refinementBar.closest('.row').siblings().attr('aria-hidden', false);
        $refinementBar.closest('.tab-pane.active').siblings().attr('aria-hidden', false);
        $refinementBar.closest('.container.search-results').siblings().attr('aria-hidden', false);
        $('.btn.filter-results').focus();
    }
};

var setRefinementCollapseStates = status => {

    if (status === 'open') {
        $('.refinement').addClass('active');
        $('.show-hide').addClass('active');
    }
    else if (status === 'closed') {
        $('.refinement').removeClass('active');
        $('.show-hide').removeClass('active');
        $('.refinement').find('button.title').attr('aria-expanded','false');
    }
};

var toggleFilters = (e) => {
    e.preventDefault();
    var toggleDirection = 'open';
    if ($('.refinement-bar .refinement').hasClass('active')) {
        toggleDirection = 'closed';
    }
    setRefinementCollapseStates(toggleDirection);
};

exports.closeRefinements = function () {
    $('html').on('click', '.js-close-refinements, .mobile-filter-drawer-in .modal-background', () => toggleRefinementDrawer('close'));
}

exports.applyFilter = function () {
    var $xhr;
    // Handle refinement value selection and reset click
    $('.container').on(
        'click',
        '.refinements li button, .js-clear-refinements, .filter-value button, .swatch-filter button',
        function (event) {
            var category = $(event.currentTarget).closest('.refinement');

            if (category && category.hasClass('refinement-category')) {
                localStorage.setItem('refinement-category', true);
                return;
            }
            //find new attr on Sort if selected, if not use the default
            var selectedSort = $('select[name="sort-order"]').find('option[selected="selected"]');
            if (selectedSort.data('id') != null) {
                var sortingRule = selectedSort.data('id');
            } else {
                var sortingRule = $('select[name="sort-order"] option:selected').data('id');
            }
            //create a new url with the correct preferences
            if ($(this).data('href').includes('srule')) {
                var refinementUrl = $(this).data('href').replace(/(srule=).*?(&|$)/,'$1' + sortingRule + '$2');
            } else if (!$(this).data('href').includes('srule') && sortingRule != null) {
                var split = $(this).data('href').indexOf('?') !== -1 ? '&' : '?';
                var refinementUrl = $(this).data('href') + split + 'srule=' + sortingRule;
            } else {
                var refinementUrl = $(this).data('href');
            }

            event.preventDefault();
            event.stopPropagation();
            $(this).trigger('search:filter', event);

            // Cancel previous request
            $xhr && $xhr.abort && $xhr.abort();

            $xhr = $.ajax({
                url: refinementUrl,
                data: {
                    page: $('.grid-footer').data('page-number'),
                    selectedUrl: refinementUrl
                },
                method: 'GET',
                timeout: 10000,
                beforeSend: function () {
                    $.spinner().start();
                },
                success: function (response) {

                    parseResults(response);
                    history.replaceState(undefined, '', refinementUrl);
                    $('body').trigger('search:filter--success');
                },
                complete: function () {
                    $.spinner().stop();
                }
            });
        });
};

/* Extended from base to use closest [data-component-id] element as context if found */
 exports.sort = function () {
    var $xhr;
    // Handle sort order menu selection
    $('.container').on('change', '[name=sort-order]', function (e) {
        e.preventDefault();
        e.stopPropagation();

        // next two lines are my work in progress
        var $container = $(this).closest('[data-component-id]');
        $container = $container.length ? $container : $(document);

        var thisValue = this.value;
        $(this).trigger('search:sort', thisValue);
        var sortOrder = $(this).val().split('srule')[1];
        var url = window.location.toString().split('&srule')[0];
        if (url.includes('srule')) {
            url = window.location.toString().split('?srule')[0];
        }
        var split = url.indexOf('?') !== -1 ? '&' : '?';
        var newUrl = url + split + 'srule' + sortOrder;

        var newSelection = this.selectedOptions[0];
        $('[name="sort-order"]').find('option[selected="selected"], option:selected').prop('selected', false).removeAttr('selected');
        $(newSelection).prop('selected', true).attr('selected', true);

        // Cancel previous request
        $xhr && $xhr.abort && $xhr.abort();

        $xhr = $.ajax({
            url: thisValue,
            data: {
                selectedUrl: thisValue,
                isSortUpdate: true
            },
            method: 'GET',
            timeout: 10000,
            beforeSend: function () {
                $.spinner().start();
            },
            success: function (response) {
                var componentId = $container.attr('data-component-id');
                var $productGridElement;
                if (componentId) {
                    $productGridElement = $(response).find(`[data-component-id="${componentId}"] .product-grid`); // if response is coming from page designer
                } else {
                    $productGridElement = $(response).find('.product-grid'); // if response is coming from page designer
                }
                var updatedGridHtml = $productGridElement.length > 0 ? $productGridElement.children() : response;
                $('.product-grid', $container).empty().html(updatedGridHtml);
                history.replaceState(undefined, '', newUrl);
                $('body').trigger('search:sort--success');
                $('body').trigger('ajax:load.ajaxEvents', [$('.product-grid')]);
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    });
}

exports.toggle = () => $('html').on('click', '.js-toggle-filters', (e) => toggleFilters(e));

module.exports = exports;
