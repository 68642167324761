'use strict';

// Creating a global getHeaderHeight function since its used more than once
// used in:
// search.js
// menu.js

function getHeaderHeight() {
    var $header = $('header');
    var $headerBanner = $header.find('.header-banner');
    var $headerNav = $header.find('.header-nav');
    var $preNavHeader = $header.find('.pre-nav-header');
    var $preNavHeaderHeight = $preNavHeader && $preNavHeader.length > 0 ? $preNavHeader.outerHeight() : 0;
    var headerNavHeight = !$headerBanner.hasClass('d-none') ? $headerNav.outerHeight() + $headerBanner.outerHeight() + $preNavHeaderHeight : $headerNav.outerHeight() + $preNavHeaderHeight;

    return headerNavHeight;
}

module.exports = {
    getHeaderHeight: getHeaderHeight
};
