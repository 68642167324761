'use strict';

var processInclude = require('base/util');

var baseFiles = {
    search: require('./search/search'),
    // Taken directly from rvw_integrations_core's search.js
    initSiteIntegrations: function initSiteIntegrations() {
        // grab the site pref integration object
        var siteIntegrations = require('integrations/integrations/siteIntegrationsUtils');
        var toggleObject = siteIntegrations.getIntegrationSettings();

        if (toggleObject.yotpoCartridgeEnabled) {
            $('body').on('search:showMore--success search:filter--success search:sort--success', () => {
                window.yotpo.refreshWidgets();
            });
        }
    }
}

$(document).ready(function () {
    Object.keys(baseFiles).forEach(function (key) {
        processInclude(baseFiles[key]);
    });
});

module.exports = {
    baseFiles
};
